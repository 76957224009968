.button_menu {
  transition: all .2s ease;
  border-radius: 0 6px 6px 0;
  padding: 2px 5px;
  color: #6e6e6e;
  display: grid;
  place-items: center;
}

.content_config_phone {
  position: fixed;
  right: 0;
  bottom: 4.5rem;
  height: 37px;
  width: 38px;
  color: #fff;
  background-color: #19243f;
  align-items: center;
  display: flex;
  border-radius: 8px 0 0 8px;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  cursor: pointer;
}

.content_button_diretory {
  position: fixed;
  right: .4rem;
  top:4.5rem;
  height: 38px;
  width: 42px;
  align-items: center;
  display: flex;
  border-radius: 8px;
  justify-content: center;
  cursor: pointer;
  animation: animation_directory_button .2s ease;
  transition: all .3s ease;
}

@keyframes animation_directory_button {
  0% {
    opacity: 0.5;
    transform: translate(30px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.content_button_diretory:hover {
  background-color: #247fb462;
}

@keyframes animation_directory_button_out {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    transform: translate(30px, 10px);
  }
}

.content_button_open_chat.hidden {
  animation: animation_chat_button_out 0.4s ease forwards;
}

@keyframes animation_chat_button_out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(30px);
  }
}

.floating-button-PhonePanel.hidden {
  animation: animation_panel_button_out 0.6s ease forwards;
}

@keyframes animation_panel_button_out {
  0% {
    opacity: 1;
    transform: translate(0, 0);
  }
  100% {
    opacity: 0;
    transform: translate(30px, -10px);
  }
}

.button_regresar {
  border: 1px solid #ddd;
  background-color: transparent;
  border-radius: 6px;
  color: #7b8494;
  transition: all .2s ease;
  padding: 2px 5px;
  display: grid;
  place-items: center;
}

.button_regresar_dark {
  border: 1px solid #ddd;
  background-color: transparent;
  border-radius: 6px;
  color: #ddd;
  transition: all .2s ease;
  padding: 2px 5px;
  display: grid;
  place-items: center;
}

.button_regresar:hover, .button_regresar_dark:hover {
  color: #2fa4e7;
  border: 1px solid #2fa4e7;
}

.content_menu_tools {
  position: fixed;
  right: 0;
  bottom: 2.5rem;
  cursor: pointer;
  width: 50px;
  padding: .75rem .2rem .75rem .3rem;
  border-radius: 30px 0 0 30px;
  background-color: #19243f;
  display: flex;
  justify-content: start;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  transform: translateX(25px);
  transition: transform 0.3s ease, padding 0.3s ease, all .3s ease;
  z-index: 9999;
}

.content_menu_tools:hover {
  transform: translateX(0);
  padding: .75rem .3rem .75rem .75rem;
}

.button_menu_tools_close {
  position: fixed;
  bottom: 2.5rem;
  right: .4rem;
  background-color: #19243f;
  border-radius: 8px;
  padding: .75rem;
  display: flex;
  justify-content: start;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  z-index: 9999;
  cursor: pointer;
  transition: all .3s ease;
}

.icon_chevron_tools {
  transform: rotate(0deg);
  transition: transform .3s ease;
  color: #fff;
}

.rotate_tools {
  transform: rotate(-180deg);
  transition: transform .3s ease;
  color: #fff;
}


/*********************** Sidebar Tools ***********************/

.my-right-sidebar {
  position: fixed;
  right: 0;
  top: 0;
  width: 55px;
  height: 100%;
  transform: translateX(100%);
  transition: transform 1s ease;
  will-change: transform;
}

.my-right-sidebar.open {
  transform: translateX(0);
}