/* Estilos generales */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Arial', sans-serif;
  }
  
  body {
    background-color: #f5f5f5;
    padding: 20px;
  }
  
  .notes-app {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Encabezado */
  .notes-header {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
    padding: 10px 0;
  }
  
  .notes-header h1 {
    color: #333;
    font-size: 1.8rem;
  }
  
  .notes-controls {
    display: flex;
    gap: 10px;
  }
  
  .search-input {
    flex: 1;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .new-note-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    white-space: nowrap;
  }
  
  .new-note-btn:hover {
    background-color: #45a049;
  }
  
  /* Grid de notas */
  .notes-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .note-card {
    padding: 20px;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s, box-shadow 0.2s;
    height: 200px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .note-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }
  
  .note-card h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .note-card p {
    font-size: 0.95rem;
    color: #333;
    margin-bottom: 10px;
    line-height: 1.4;
    flex: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  
  .note-date {
    font-size: 0.75rem;
    color: #666;
    align-self: flex-end;
  }
  
  /* Editor modal */
  .editor-modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .editor-container {
    width: 90%;
    max-width: 600px;
    min-height: 400px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
  }
  
  .editor-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .color-picker {
    display: flex;
    gap: 8px;
  }
  
  .color-option {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid transparent;
    cursor: pointer;
  }
  
  .color-option:hover {
    border-color: #999;
  }
  
  .close-editor {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #666;
    padding: 5px;
  }
  
  .close-editor:hover {
    color: #333;
  }
  
  .note-title-input {
    font-size: 1.3rem;
    padding: 10px 15px;
    margin-bottom: 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.7);
  }
  
  .note-content-input {
    flex: 1;
    font-size: 1rem;
    padding: 15px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    resize: none;
    margin-bottom: 15px;
    line-height: 1.6;
    background-color: rgba(255, 255, 255, 0.7);
  }
  
  .editor-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .delete-btn-notes {
    background-color: #ff4d4d;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .delete-btn-notes:hover {
    background-color: #e60000;
  }
  
  .save-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .save-btn:hover {
    background-color: #45a049;
  }

  /* Añade esto al archivo CSS existente */

.note-card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
  }
  
  .edit-note-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    padding: 2px 5px;
    opacity: 0.7;
  }
  
  .edit-note-btn:hover {
    opacity: 1;
  }
  
  /* El resto del CSS permanece igual */