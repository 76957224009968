
.timeLine {
  max-height: 600px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

}
 /* Estilos para la barra de desplazamiento en navegadores WebKit (Safari y Chrome) */
 .timeLine::-webkit-scrollbar {
  width: 8px; /* Ancho de la barra de desplazamiento */
}

.timeLine::-webkit-scrollbar-thumb {
  background-color: rgba(131, 131, 131, 0.308); /* Color de la barra de desplazamiento */
  border-radius: 10px; /* Borde redondeado de la barra de desplazamiento */
}

.timeLine::-webkit-scrollbar-thumb:hover {
  background-color: rgba(85, 85, 85, 0.4); /* Cambia el color de la barra de desplazamiento al hacer hover */
}

.timeLine::-webkit-scrollbar-track {
  background: transparent; /* Fondo transparente */
}