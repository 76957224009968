.contain_chat {
  position: relative;
}

.chat_interno {
  position: fixed;
  top: 11.5rem;
  right: .4rem;
  cursor: pointer;
  transition: all .2s ease;
}

.content_button_open_chat {
  width: 42px;
  height: 38px;
  /* background-color: #19243f; */
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  /* box-shadow: 0 4px 8px rgba(0,0,0,0.2); */
  animation: animation_chat_button .6s ease;
  transition: all .4s ease;
}

.content_button_open_chat:hover {
  background-color: #247fb462;
}

@keyframes animation_chat_button {
  0% {
    opacity: 0.5;
    transform: translateX(30px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}



.bandeja_chat {
  position: fixed;
  bottom: 2.5rem;
  right: 4rem;
  width: 380px;
  height: 580px;
  /* border: 1px solid #202020; */
  border-radius: 6px;
  overflow: hidden;
  background-color: #292929;
  box-shadow: 0 5px 8px 0 rgb(0 0 0 / 0.2);
  z-index: 9999;
}

.content_no_users {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.p_no_users {
  font-size: .8rem;
  color: #b2b2b2;
}

.contain_contacts_connect {
  height: 55px;
  border-bottom: 1px solid #202020;
  display: flex;
  gap: .5rem;
  padding: 0 .5rem;
}

.content_contact_connect {
  position: relative;
  display: grid;
  place-items: center;
  width: 60px;
}

.content_img_chat_connect {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img_chat_connect {
  width: 35px;
}

.name_contact_connect {
  font-size: .7rem;
  color: #fff;
}

.point_connect {
  height: 10px;
  width: 10px;
  background-color: #09cc09;
  border-radius: 100%;
  position: absolute;
  border: 1px solid #fff;
  bottom: 1.35rem;
  right: .9rem;
}

.content_img_chat {
  width: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.img_chat {
  width: 40px;
}

.point_contact_disconnect {
  height: 10px;
  width: 10px;
  background-color: #b2b2b2;
  border-radius: 100%;
  position: absolute;
  border: 1px solid #fff;
  bottom: .66rem;
  right: .5rem;
}

.point_contact_connect {
  height: 10px;
  width: 10px;
  background-color: #09cc09;
  border-radius: 100%;
  position: absolute;
  border: 1px solid #fff;
  bottom: .66rem;
  right: .5rem;
}

.header_chat {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: .2rem .8rem;
  background-color: #121e2d;
  height: 40px;
}

.header_chat_ {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: .2rem .8rem;
  height: 40px;
}

.title_header_chat {
  font-size: 1.1rem;
  padding-top: 3px;
  font-weight: 500;
  color: #fff;
}

.hidden_title_header_chat {
  display: none;
}

.icon_search {
  color: #fff;
  cursor: pointer;
  padding-top: 2px;

}

.content_input_search_chat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.input_buscar_chat {
  background-color: inherit;
  color: #fff;
  border: none;
  font-size: .9rem;
  padding-top: 6px;
}

.input_buscar_chat:focus {
  outline: none;
}

.chat_opcion {
  border-bottom: 1px solid #202020;
  display: flex;
  justify-content: space-between;
  height: 55px;
  cursor: pointer;
  transition: all .2s ease;
  background-color: transparent;
}

.chat_opcion:hover {
  background-color: #202020;
}

.content_chat_info {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: 100%;
  padding-left: .3rem;
  line-height: 1.2;
}

.name_banadeja_chat {
  color: #fff;
  font-weight: 500;
  font-size: .95rem;
  padding-top: 8px;
}

.content_ultimate_message_chat {
  width: 280px;
  height: 20px;
}

.ultimate_message_chat {
  font-size: .85rem;
  color: #b2b2b2;
  overflow: hidden;
display: -webkit-box;
-webkit-box-orient: vertical;
-webkit-line-clamp: 1;
}

.content_time_last_massge {
  padding: 0 .5rem;
  padding-top: 2px;
}

.time_last_message {
  font-size: .7rem;
  color: #b2b2b2;
}




.content_name_chat {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button_volver_chat {
  color: #fff;
  cursor: pointer;
  transition: all .2s ease;
}

.button_volver_chat:hover {
  color: #b7b7b7;
}

.name_chat {
  font-size: 1rem;
  font-size: 500;
  color: #fff;
  padding-top: 3.5px;
}

.content_img_user_chat {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.content_img_user_chat:hover {
  opacity: .8;
}

.img_user_chat {
  width: 30px;
}

.bandeja_container,
.chat_content_container {
  width: 100%;
  flex-shrink: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: transform 0.3s ease;
}

.chat_container.bandeja .bandeja_container {
  transform: translateX(0);
}

.chat_container.bandeja .chat_content_container {
  transform: translateX(100%);
}

.chat_container.chat .bandeja_container {
  transform: translateX(-100%);
}

.chat_container.chat .chat_content_container {
  transform: translateX(0);
}

.hidden_chat {
  visibility: hidden;
  opacity: 0;
}

.chat_interno_chat {
  display: grid;
  grid-template-rows: auto 499px auto;
}

.content_actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 10px;
  background-color: transparent;
  color: #fff;
}

.content_micro_send {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
}

.chat_input {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: .9rem;
  width: 275px;
}

.chat_input:focus {
  outline: none;
}

.bandeja_del_chat {
  background-image: url(../../../assets/images/fondo-chat.jpg);
  background-size: cover;
  height: 500px;
  overflow: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
  padding-bottom: .5rem;
}

.button_file_chat {
  cursor: pointer;
  transition: color .2s ease;
  padding: 0;
  margin: 0;
}



.button_audio_chat {
  cursor: pointer;
  transition: color .2s ease;
}

.button_send_message {
  cursor: pointer;
  transition: color .2s ease;
}

.button_file_chat:hover,.button_audio_chat:hover, .button_send_message:hover {
  color: #b7b7b7;
}

.contain_message_contact {
  width: 100%;
  display: flex;
  justify-content: start;
}

.contain_message_user {
  width: 100%;
  display: flex;
  justify-content: end;
}

.content_message_contact {
  margin: 3px 15px;
  background-color: #19243f;
  border-radius: 6px;
  padding: .3rem .5rem;
  position: relative;
  font-size: .9rem;
  color: #fff;
  max-width: 300px;
  height: max-content;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.content_message_contact::after {
  content: '';
  position: absolute;
  top: 0;
  left: -8px; /* Ajusta según la posición del piquito */
  bottom: 5px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #19243f; /* Color del mensaje */
  border-bottom: 0;
}

.content_message_user {
  margin: 3px 10px;
  background-color: #2e2e30;
  border-radius: 6px;
  padding: .4rem .5rem;
  position: relative;
  font-size: .9rem;
  color: #fff;
  text-align: left;
  max-width: 300px;
  height: max-content;
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-height: 1.4;
}

.content_message_user::after {
  content: '';
  position: absolute;
  top: 0;
  right: -8px; /* Ajusta según la posición del piquito */
  bottom: 5px;
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-top-color: #2e2e30; /* Color del mensaje */
  border-bottom: 0;
}

.nessage {
  color: #fff;
  font-size: .85rem;
  width: 100%;
  height: max-content;
  padding: 0 .3rem;
}

.content_message_ {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .4rem;
}

.message_time {
  font-size: .65rem;
  color: #b7b7b7;
  height: 100%;
  bottom: 0;
}

.content_fecha_massage {
  display: flex;
  justify-content: center;
  align-items: end;
  height: 4px;
}

.message_caption {
  color: #fff;
  font-size: .85rem;
  width: 100%;
  height: max-content;
  padding: 0 .2rem;

}

.content_massage_image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 300px;
  background-color: #000;
  cursor: pointer;
}

.message_image {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.content_date_header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .5rem 0;
}

.date_header {
  color: #fff;
  font-size: .7rem;
  background-color: rgb(109, 109, 109, 0.3);
  padding: 2px 10px;
  border-radius: 6px;
  width: max-content;
}

::-webkit-scrollbar {
  width: 4px;
  background-color: rgba(13,15,16,.8);
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(13,15,16,.8);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #202020;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  opacity: .5;
}



.content_loading_chat_app {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content_icon_loading {
  width: 70px;
  animation: vanisherLoading 1.7s ease;
}

@keyframes vanisherLoading {
  0% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.bandeja_de_entrada__ {
  position: relative;
  width: 100%;
  height: 100%;
}

.content_message_new {
  background-color: #19243f;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

.button_new_message {
  color: #fff;
}

.loading_spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  width: 35px;
  height: 35px;
  border: 4px solid transparent;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  background-color: #2e2e30;
  animation: spin .6s linear infinite;
}

/* Animación de giro */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Asegúrate de que el modal tiene suficiente z-index y estilos de visibilidad */
.file_modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9); /* Fondo más oscuro */
  display: flex;
  justify-content: center;
  align-items: start;
  z-index: 9999; /* Asegúrate de que esté encima de otros elementos */
}

/* Estilos para el modal */
.file_modal { /* Ancho máximo para que no se vea demasiado grande */
  width: 100%;
  height: 538px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Agregar sombra */
}

/* Estilo para la vista previa de la imagen */
.file_preview {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* Imagen de vista previa */
.file_preview_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Estilo para el campo de texto de caption */
.content_buttons_actions_modal {
  display: flex;
  justify-content: space-between;
  background-color: #2e2e30;
  align-items: center;
  padding: 7px 10px;
  color: #fff;
}

.caption_input {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: .9rem;
  width: 280px;
}

.caption_input:focus {
  outline: none;
}

.cancel_file_chat {
  cursor: pointer;
  transition: color .2s ease;
}

.send_file_chat {
  cursor: pointer;
  transition: color .2s ease;
}

.send_file_chat:hover,.cancel_file_chat:hover {
  color: #b7b7b7;
}


/* Asegúrate de que el modal tiene suficiente z-index y estilos de visibilidad */
.image_modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.95); /* Fondo más oscuro */
  display: flex;
  justify-content: center;
  align-items: start;
  z-index: 9999; /* Asegúrate de que esté encima de otros elementos */
}

/* Estilos para el modal */
.image_modal { /* Ancho máximo para que no se vea demasiado grande */
  width: 100%;
  height: 100%;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Agregar sombra */
}

/* Estilo para la vista previa de la imagen */
.image_preview {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/* Imagen de vista previa */
.image_preview_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image_modal_close {
  position: absolute;
  padding: .5rem;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 6px;
  cursor: pointer;
}

.image_modal_close:hover {
  color: #b7b7b7;
}

.content_image_caption {
  position: absolute;
  bottom: 2.5rem;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.image_caption {
  color: #fff;
  font-size: .85rem;
  text-align: center;
  padding: .5rem;
}


/********* LISTA DE CONTACTOS **********/

.new_chat_overlay {
  position: fixed;
  animation: contacts .3s ease;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #2e2e30;
  z-index: 1000;
}

@keyframes contacts {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}


.header_chat_contact {
  display: flex;
  align-items: start;
  padding: .2rem .8rem;
  gap: .5rem;
  background-color: #19243f;
  height: 40px;
}

.new_chat_list {
  height: 535px;
  overflow: auto;
  scroll-behavior: smooth;
  overflow-x: hidden;
}

.patner_info_chat {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p_patner_chat {
  font-size: .8rem;
  color: #b2b2b2;
}