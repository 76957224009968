.chat-app {
    display: flex;
    height: 100vh;
    font-family: Arial, sans-serif;
  }
  
  .sidebar {
    width: 300px;
    background-color: #f4f4f4;
    padding: 20px;
    border-right: 1px solid #ddd;
  }
  
  .new-chat-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .chat-history {
    margin-top: 20px;
  }
  
  .chat-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
  }
  
  .chat-item:hover {
    background-color: #e9ecef;
  }
  
  .chat-title {
    font-weight: bold;
  }
  
  .chat-date {
    font-size: 12px;
    color: #666;
  }