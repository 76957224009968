.contain_emails {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  padding: 1rem 0;
}

.contain_inbox_email {
  width: 220px;
  padding: .5rem 0;
  display: grid;
}

.content_inbox_email {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: .5rem;
  cursor: pointer;
  transition: all .2s ease;
  padding: .7rem 1rem;
}

.content_inbox_email:hover {
  background-color: #dadce0 ;
}

.content_inbox_email_dark {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: .5rem;
  cursor: pointer;
  transition: all .2s ease;
  padding: .7rem 1rem;
}

.content_inbox_email_dark:hover {
  background-color: #091018 ;
}

.opcion_inbox_email {
  font-size: .95rem;
}

.content_bandeja_emails {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  padding: .5rem 0;

}

.content_button_redactar_email {
  padding: 0 0 .5rem 1rem;
}

.button_redactar_email {
  background-color: #0576b9;
  color: #fff;
  padding: .8rem 1rem ;
  width: max-content;
  border-radius: 8px;
  transition: all .2s ease;
  display: flex;
  align-items: center;
  gap: .3rem;
  font-weight: 600;
  cursor: pointer;
}

.button_redactar_email:hover {
  background-color: #00639c;
}

.content_title_search {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content_input_search_email {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem 0 0;
}

.input_search_email {
  width: 400px;
  padding: 9px 11px;
  font-size: .9rem;
  border-radius: 6px 0 0 6px;
  outline: none;
  border: none;
}

.button_search_email {
  border: none;
  border-radius: 0 6px 6px 0;
  cursor: pointer;
  padding: 7.5px 8px;
  background-color: #0576b9;
  color: #fff;
}

.contain_acciones_emails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
}

.content_acciones_email {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.check_accion_email {
  width: 17px;
  height: 17px;
  border: 1px solid #495057;
  border-radius: 4px;
  cursor: pointer;
}