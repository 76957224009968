/* App.css */
.ds-chat-container {
    display: flex;
    width: 100%;
    height: 88vh;
    background-color: #f4f4f9;
  }
  
  .ds-chat-history {
    width: 350px; /* Ancho fijo para el historial */
    background-color: #2c3e50;
    color: white;
    padding: 10px;
    overflow-y: auto;
    flex-shrink: 0; /* Evitar que se reduzca */
  }
  
  .ds-chat-window {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-left: 1px solid #ddd;
    min-width: 0; /* Permitir que el contenido se ajuste */
    overflow: hidden; /* Evitar desbordamiento */
  }
  
  .ds-chat-messages {
    flex-grow: 1;
    padding: 30px;
    overflow-y: auto;
    overflow-x: hidden; /* Evitar desbordamiento horizontal */
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    gap: 10px; /* Espacio entre mensajes */
  }
  
  .ds-chat-input {
    display: flex;
    padding: 10px;
    background-color: #fff;
    border-top: 1px solid #ddd;
  }
  
  .ds-chat-input__field {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-right: 10px;
  }
  
  .ds-chat-input__button {
    padding: 10px 20px;
    background-color: #2c3e50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .ds-chat-input__button:hover {
    background-color: #34495e;
  }
  
  .ds-message {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
  }
  
  .ds-message--user {
    align-items: flex-end; /* Alinear mensajes del usuario a la derecha */
  }
  
  .ds-message--ai {
    align-items: flex-start; /* Alinear mensajes de la IA a la izquierda */
  }
  
  .ds-message__content {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 15px;
    max-width: 70%; /* Limitar el ancho máximo */
    word-wrap: break-word; /* Asegurar que el texto largo no desborde */
  }
  
  .ds-message--user .ds-message__content {
    background-color: #2c3e50;
    color: white;
  }
  
  .ds-message--ai .ds-message__content {
    background-color: #e1e1e1;
    color: black;
  }
  
  .ds-loading-animation {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #2c3e50;
    margin: 0 5px;
    animation: ds-bounce 1.4s infinite ease-in-out;
  }
  
  .ds-loading-animation:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .ds-loading-animation:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes ds-bounce {
    0%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-10px);
    }
  }
  
  /* Estilos para el botón */
  .ds-chat-history__button {
    padding: 10px 20px;
    margin-top: 17px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 25px; /* Bordes redondeados */
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .ds-chat-history__button:hover {
    background-color: #2980b9;
    transform: translateY(-2px); /* Efecto de levantar */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  
  .ds-chat-history__button:active {
    transform: translateY(0);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Estilos para el historial de chats */
  .ds-chat-history__list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0;
  }
  
  .ds-chat-history__item {
    background-color: #34495e;
    color: white;
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .ds-chat-history__item:hover {
    background-color: #3b4f63;
    transform: translateX(10px); /* Desplazamiento horizontal */
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  
  .ds-chat-history__item:active {
    transform: translateX(5px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Animación de entrada para las tarjetas */
  @keyframes ds-slide-in {
    from {
      opacity: 0;
      transform: translateX(-20px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .ds-chat-history__item {
    animation: ds-slide-in 0.5s ease-out;
  }
  
  /* Cursor de "escribiendo" */
  .ds-typing-cursor {
    display: inline-block;
    width: 2px;
    height: 1em;
    background-color: black;
    margin-left: 2px;
    animation: blink 1s infinite;
  }
  
  @keyframes blink {
    0%, 50% {
      opacity: 1;
    }
    51%, 100% {
      opacity: 0;
    }
  }
  /* Estilos para el botón de "Stop" */
.ds-stop-button {
    position: absolute; /* Posicionamiento absoluto dentro del contenedor de chat */
    top: 10px; /* Distancia desde la parte superior */
    right: 10px; /* Distancia desde la derecha */
    padding: 6px 12px; /* Tamaño más pequeño */
    background-color: #e74c3c; /* Color de fondo */
    color: white; /* Color del texto */
    border: none;
    border-radius: 4px; /* Bordes más redondeados */
    cursor: pointer;
    font-size: 12px; /* Tamaño de fuente más pequeño */
    font-weight: bold;
    transition: all 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra más sutil */
    z-index: 10; /* Asegurar que esté por encima de otros elementos */
  }
  
  .ds-stop-button:hover {
    background-color: #c0392b; /* Color de fondo al hacer hover */
    transform: translateY(-1px); /* Efecto de levantar */
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15); /* Sombra más pronunciada al hacer hover */
  }
  
  .ds-stop-button:active {
    transform: translateY(0); /* Restablecer la posición al hacer clic */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Restablecer la sombra al hacer clic */
  }