.card {
  width: 250px;
  max-width: 250px;
  height: 204px;
  background: #f5f5f5;
  padding: 2rem 1.5rem;
  transition: box-shadow .3s ease, transform .2s ease;
  position: relative;
  cursor: pointer;
 }
 
 .card-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform .2s ease, opacity .2s ease;
 }
 
 /*Image*/
 .card-avatar {
  --size: 90px;
  background: linear-gradient(to top, #8befff 0%, rgb(71, 71, 87) 100%);
  width: var(--size);
  height: var(--size);
  padding: 4px;
  border-radius: 50%;
  transition: transform .2s ease;
  margin-bottom: 1rem;
 }
 
.avatar {
  --size: 200px;
  background: linear-gradient(to top, #8befff 0%, rgb(11, 12, 41) 100%);
  width: var(--size);
  height: var(--size);
  padding: 4px;
  border-radius: 50%;
  transition: transform .2s ease;
}
 /*Text*/
 .card-title {
  color: #333;
  font-size: 1.1em;
  font-weight: 600;
  line-height: 1.5rem;
  margin-bottom: 15px;
 }
 
 .card-subtitle {
  color: #859ba8;
  font-size: 0.8em;
 }
 
 /*Hover*/
 .card:hover {
  box-shadow: 0 8px 50px #23232333;
 }
 
 .card:hover .card-info {
  transform: translateY(-5%);
 }
 
 .card:hover .card-social {
  transform: translateY(100%);
  opacity: 1;
 }
 
 .card-avatar:hover {
  transform: scale(1.1);
 }
 
/* Estilos del contenedor grid */
/* Estilos del contenedor grid */
.card-container {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, 0fr);
  gap: 10px;
}
@media (max-width: 1100px) {
  .card-container {
    grid-template-columns: repeat(3, 0fr);
  }
}
/* Estilos para pantallas más pequeñas */
@media (max-width: 788px) {
  .card-container {
    grid-template-columns: repeat(2, 0fr);
  }
}
/* Estilos para pantallas aún más pequeñas */
@media (max-width: 576px) {
  .card-container {
    grid-template-columns: 0fr;
  }
}
/* Estilos para los botones fijos izquierdo y derecho */
.fixed-button-left {
  position: absolute;
  top: 23%;
  left: 0;
  transform: translateY(-50%);
  padding: 10px;

}

.fixed-button-right {
  position: absolute;
  top: 15%;
  right: 0;
  transform: translateY(-50%);
  padding: 5px;
}

.fixed-button {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 2px;
  padding-bottom: 0;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  border-top: 2px solid #e9e9e9;
  width: 100%;
  z-index: 100; /* Asegura que este contenedor tenga un z-index alto */
}

.whatsapp-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  z-index: 2000; /* Asegura que el menú esté por encima de otros elementos */
}


.social-icons-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.social-icon {
  width: 24px;
  height: 24px;
  margin: 5px;
  cursor: pointer;
  /* Puedes personalizar más estilos según tus necesidades */
}

 .social-icon:hover  {
  transform: translateY(500%);
  opacity: 1;
 }
 
 .social-icon:hover {
  transform: scale(1.4);
 }

.editMenu {
  position: relative;
  top: 5px; /* Ajusta según sea necesario */
  right: 5px; /* Ajusta según sea necesario */
  color: #f5f5f5;
  cursor: pointer;
}


