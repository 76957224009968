
.ticket {
  width: 250px;
  height: 330px;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #d2d2d2;
  border-radius: 8px;
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, .03);
}

.ticket-header {
  color: #fff;
  position: relative;
  display: flex;
  height: 60px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; /* Alinea el texto al centro horizontalmente */
  padding: 8px;
}

.contain_img_caso {
  position: absolute;
  bottom: -70%;
}

.content_img_caso {
  width: 90px;
  height: 90px;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d2d2d2;
  background-color: #fff;
}

.img_caso {
  width: 90px;
}

.img_caso_ticket {
  width: 75px;
}

.ticket-header h4 {
  margin: 0; /* Elimina el margen predeterminado del encabezado h4 */
  
}
/* .ticket-header:before, .ticket-header:after {
  content: '';
  position: absolute;
  width: 24px;
  height: 24px;
  background: #eeeded;
  border-radius: 100%;
  bottom: -12px;
  border: 2px solid #00204bce;
  box-sizing: border-box;
  }
  .ticket-header:before { 
    left: -13px;
    }
    .ticket-header:after {
      right: -13px;
      } */
.ticket-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 3rem 0 0 0;
}
      
.content_name_caso {
  text-align: center;
  line-height: 1.2;
  width: 200px;
  height: 65px;
}

.name_caso {
  font-weight: 600;
}

.content_info_caso_card {
  width: 200px;
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.5rem;
  row-gap: 0.5rem;
  align-items: start;
}

.content_title_info_caso {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 0.5rem;
}

.content_info_caso {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 0.5rem;
  white-space: nowrap;         /* Evita el salto de línea */
  overflow: hidden;            /* Oculta cualquier contenido que se desborde */
  text-overflow: ellipsis; 
}

.content_title_info_caso > span,
.content_info_caso > span {
  line-height: 1.2;
}

.content_info_caso > span {
  font-weight: 600;
}

.descripcion_casos {
  font-weight: 600;
}

.content_img_checket {
  padding-top: 13px;
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img_checket {
  width: 30px;
  height: 30px;
}

.ticket-fixed-button {
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
}

.dropdown-container {
  position: absolute;
  left: 10px; /* Ajusta este valor según sea necesario */
  top: 0;
  padding: 5px;
  z-index: 1000;
}

.hover-scale {
  transition: transform 0.2s ease-in-out; /* Animación suave */
}

.hover-scale:hover {
  transform: scale(1.2); /* Aumenta el tamaño al pasar el mouse */
}

.btn_pausar {
  background-color: #3272b1;
  border: 1px solid #3272b1;
  border-radius: 4px;
  color: #fff;
  transition: all .2s ease;
  font-size: .9rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_pausar:hover {
  background-color: #265686;
  border: 1px solid #265686;
}

.btn_finalizar {
  background-color: #0cb80c;
  border: 1px solid #0cb80c;
  border-radius: 4px;
  color: #fff;
  transition: all .2s ease;
  font-size: .9rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_finalizar:hover {
  background-color: #098f09;
  border: 1px solid #098f09;
}

.content_btn_asignar {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: end;
  padding-bottom: 1.1rem;
}

.btn_asignar {
  background-color: #2fa4e7;
  border: 1px solid #2fa4e7;
  border-radius: 4px;
  color: #fff;
  transition: all .2s ease;
  font-size: .9rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_asignar:hover {
  background-color: #247fb4;
  border: 1px solid #247fb4;
}

.button-view {
  border: 1px solid #d2d2d2;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button-view-dark {
  border: 1px solid #162436;
  background-color: #121e2d;
  border-radius: 6px;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #c3cbe4;
}

.sindata_ {
  color: #2fa4e7;
  color: #3272b133;
}

.card-container {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, 0fr);
  gap: 1rem;
  padding: 0 1rem;
}
@media (max-width: 1100px) {
  .card-container {
    grid-template-columns: repeat(3, 0fr);
  }
}
/* Estilos para pantallas más pequeñas */
@media (max-width: 788px) {
  .card-container {
    grid-template-columns: repeat(2, 0fr);
  }
}
/* Estilos para pantallas aún más pequeñas */
@media (max-width: 576px) {
  .card-container {
    grid-template-columns: 0fr;
  }
}


/***** Botones estado casos *****/

.button_estado_caso {
  border-radius: 6px;
  border: 1px solid #d2d2d2;
  font-size: .8rem;
  font-weight: 500;
  background-color: #fff;
  cursor: pointer;
  padding: 5px 10px;
}
.button_estado_caso_dark {
  border-radius: 6px;
  border: 1px solid #162436;
  font-size: .8rem;
  font-weight: 500;
  background-color: #121e2d;
  cursor: pointer;
  padding: 5px 10px;
  color: #c3cbe4;
}

.button_todas_active {
  background-color: #fe8095;
  color: #fff;
  border: 1px solid #fe8095;
}

.button_asignado_active {
  background-color: #49b0ea;
  border: 1px solid #49b0ea;
  color: #fff;
}

.button_sinasignar_active {
  background-color: #e16b20;
  color: #fff;
  border: 1px solid #e16b20;
}

.button_pendiente_active {
  background-color: #bf1b21;
  color: #fff;
  border: 1px solid #bf1b21;
}

.button_enpausa_active {
  background-color: #3272b1;
  color: #fff;
  border: 1px solid #3272b1;
}

.button_enproceso_active {
  background-color: #915eef;
  color: #fff;
  border: 1px solid #915eef;
}

.button_finalizado_active {
  background-color: #09cc09;
  color: #fff;
  border: 1px solid #09cc09;
}

.content_search_input {
  display: flex;
  justify-content: start;
  align-items: center;
}

.input_search_casos {
  border: 1px solid #d2d2d2;
  outline: none;
  font-size: .9rem;
  padding: 4px 10px;
  border-radius: 6px 0 0 6px;
  background-color: #fff;
  min-width: 100px;
}

.input_search_casos_dark {
  border: 1px solid #162436;
  outline: none;
  font-size: .9rem;
  padding: 4px 10px;
  border-radius: 6px 0 0 6px;
  background-color: #121e2d;
  color: #c3cbe4;
  min-width: 100px;
}

.button_x_casos {
  border: none;
  border-radius: 0 6px 6px 0;
  padding: 3px 6px 5px 6px ;
  align-items: center;
  background-color: #2fa4e7;
  color: #fff;
}

.button_chevron_fecha {
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;
}

.content_fecha_cass {
  height: 35px;
  width: 120px;
  display: grid;
  place-items: center;
  border-radius: 6px;
  padding: 6px 0;
}

.fecha_casos_ {
  font-size: .725rem;
  font-weight: 700;
  text-align: center;
  line-height: 1;
}

.hora_fecha_casos {
  font-size: .55rem;
  text-align: center;
}

.tb_layout_menu_casos {
  display: grid;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.contain_calendar_casos {
  display: grid;
  grid-template-columns: auto 1fr;
  border-top: 1px solid #e9ebec99;
  
}

.content_menu_casos {
  width: 210px;
  border-right: 1px solid #e9ebec99;
}

.content_casos_en_calendario {
  display: grid;
  grid-template-rows: auto 1fr;
}

.content_agentes_casos {
  height: 50px;
  box-shadow:  0 2px 5px 0 rgb(0 0 0 / 0.1);
  display: flex;
}

.content_agentes {
  display: flex;
}

.content_agente_casos {
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  
}

.content_img_agente {
  width: 55px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img_agente {
  width: 30px;
}

.content_info_agente {
  display: grid;
  justify-content: start;
  align-items: center;
  min-width: max-content;
  width: 260px;
}

.name_agente_casos {
  font-size: .725rem;
  font-weight: 600;
}

.hora_trabajo_agente {
  font-size: .6rem;
  color: #979797;
}

.content_horario_casos {
}



/**************** CASOS DETAILS *****************/

.ticket_detail {
  width: 950px;
  overflow: hidden;
  cursor: pointer;
  padding: .75rem 1rem  0 1rem;
}

.ticket_header_detail {
  position: relative;
  display: flex;
  flex-direction: column;
}

.content_title_caso_detail {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .75rem;
}

.title_caso_detail {
  font-size: 1.5rem;
  font-weight: 600;
}

.text_info_caso_detail {
  font-weight: 600;
}

.ticket_header_detail h4 {
  margin: 0; /* Elimina el margen predeterminado del encabezado h4 */

}


.ticket_body_detail {
  max-height: 1000px; /* Establece la altura máxima */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: .5rem 1rem;
}

.dropdown_container_detail {
  position: absolute;
  left: 10px; /* Ajusta este valor según sea necesario */
  top: 0;
  padding: 5px;
  z-index: 1000;
}

.timeLine_detail {
  max-height: 600px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

}
 /* Estilos para la barra de desplazamiento en navegadores WebKit (Safari y Chrome) */
.timeLine_detail::-webkit-scrollbar {
  width: 8px; /* Ancho de la barra de desplazamiento */
}

.timeLine_detail::-webkit-scrollbar-thumb {
  background-color: rgba(131, 131, 131, 0.308); /* Color de la barra de desplazamiento */
  border-radius: 10px; /* Borde redondeado de la barra de desplazamiento */
}

.timeLine_detail::-webkit-scrollbar-thumb:hover {
  background-color: rgba(85, 85, 85, 0.4); /* Cambia el color de la barra de desplazamiento al hacer hover */
}

.timeLine_detail::-webkit-scrollbar-track {
  background: transparent; /* Fondo transparente */
}

.contain_images_caso {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: .5rem;
  width: 100%;
}

.content_img_caso_detail {
  width: 100%;
  height: 400px;
}

.content_img_miniatura_casos {
  display: grid;
  align-items: start;
  gap: .5rem;
}

.img_caso_detail {
  border: 1px solid #d2d2d2;
  background-color: #f2f3f4;
  padding: 1px;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  height: 420px;
  object-fit: contain;
}

.img_caso_detail_dark {
  border: 1px solid #121e2d;
  background-color: #0e1824;
  padding: 1px;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  height: 420px;
  object-fit: contain;
}

.content_img_miniatura_casos {
  cursor: pointer;
  border-radius: 5px;
  width: 75px;
  height: 75px;
  display: grid;
  place-items: center;
}

.img_miniatura_caso_detail {
  width: 65px;
  height: 65px;
  object-fit: cover;
  border-radius: 5px;

}
