.button_view_contant {
  border: 1px solid #d2d2d2;
  border-radius: 6px;
  background-color: #fff;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button_view_dark {
  border: 1px solid #162436;
  background-color: #121e2d;
  border-radius: 6px;
  padding: 0 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #c3cbe4;
}