.session-timer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Arial', sans-serif;
    margin: auto;
}

.timer-display {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    margin-right: 15px;
}

.pause-button, .play-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    border-radius: 50%;
    transition: background-color 0.3s ease;
}

.pause-button {
    color: #ff6b6b; /* Color rojo para el botón de pausa */
}

.play-button {
    color: #4caf50; /* Color verde para el botón de play */
}