.contain_navbar_calendar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.content_title_navbar_calendar {
  display: flex; 
  align-items: center;
  gap: 1rem;
  
}

.title_navbar_cal {
  font-size: 1.45rem;
  letter-spacing: -.5px;
}

.content_button_reset_fecha {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.button_resetFecha {
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background-color: transparent;
  padding: 6px 14px;
  transition: all .2s ease;
  border: 1px solid #2fa4e7;
}

.button_resetFecha:hover {
  background-color: #247fb462;

}

.content_fecha_indicador {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.content_button_search {
  background-color: transparent;
  border-radius: 8px;
  padding: 5px 8px;
  cursor: pointer;
  transition: all .2s ease;
}

.content_button_search:hover {
  background-color: #247fb462;

}


/******************** SIDEBARCALENDAR ***********************/

.content_calendar_sidebar {
  width: 220px;
  padding: 2rem 10px;
  display: grid;
  gap: 1rem;
}

.content_crear_button {
  padding: 0 0 1rem 0;
}

.button_crear_caledar {
  border: none;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background-color: #2fa4e7;
  color: #fff;
  padding: 8px 14px;
  transition: all .2s ease;
}

.button_crear_caledar:hover {
  background-color: #247fb4;
}

.content_mi_calendario {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  transition: all .2s ease;
  padding: 0 5px;
  border-radius: 6px;
}

.content_mi_calendario:hover {
  background-color: #247fb462;
}

.content_opcions_mi_calendario {
  max-height: 0;
  overflow: hidden;
  transition: max-height .4s ease-in-out;
  
}

.active_options_mi_calendario {
  max-height: 300px;
  transition: max-height .4s ease-in-out;
  display: grid;
}

.opcion_mi_calendario {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s ease;
  padding: 10px 15px 0 15px;
}

.button_add_calendario {
  background-color: transparent;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  transition: all .3s ease;
}

.button_add_calendario:hover {
  background-color: #2fa4e7;
}

.icon_chevron {
  transform: rotate(0deg);
  transition: transform .3s ease;
}

.rotate {
  transform: rotate(90deg);
  transition: transform .3s ease;
}

/************************************************************/

/*********************** MainCalendarContent ***********************/


.content_navbar_main {
  display: grid;
  grid-template-columns: auto 1fr;
}

.contain_calendar_events {
  margin: 1rem;
  background-color: #121e2d;
  border-radius: 10px;
  overflow: hidden;
  transition: all .3s ease;
}

.calendar_container {
  padding: 1rem;
  position: relative;
  width: 100%;
  height: 600px;
  overflow: hidden;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
}

.hour_block {
  flex: 1;
  border-bottom: 1px solid #666;
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 8px;
}

.hour_label {
  font-size: 0.8rem;
  color: #c3cbe4;
}

.current_time_line {
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background-color: red;
  z-index: 0;
}

/* Contenedor general que muestra la vista de un día */

._calendar_contain_ {
  border-radius: 20px;
  overflow: hidden;
  margin: 1rem;
}

.fade_zoom {
  opacity: 0;
  animation: fadeIn 0.3s ease-in-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.content_calendar_events {
  height: 575px;
  overflow-y: scroll;
  position: relative;
  padding-right: .5rem;
}

.content_dia_actual_events {
  display: grid;
  margin-left: 4rem;
  width: max-content;
  padding: 1rem .5rem 0 .5rem;
}

.content_dia_semana_events {
  display: grid;
  place-content: center;
  width: 100%;
  padding: .5rem .5rem 0 .5rem;
}

.name_dia_actual_events {
  font-size: .9rem;
  text-align: center;
}

.dia_actual_events {
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2fa4e7;
  color: #000;
  font-weight: 600;
  height: 45px;
  width: 45px;
  border-radius: 100%;
}

.dia_events {
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  font-weight: 600;
  height: 45px;
  width: 45px;
  border-radius: 100%;
  transition: all .3s ease;
}

.dia_events:hover {
  background-color: #247fb462;
}

.day_view_container {
  display: flex;
}

.time_labels {
  width: 65px;
  display: flex;
  flex-direction: column;
}

.time_label {
  height: 50px;
  line-height: 50px; 
  text-align: right;
  padding-right: 15px;
  font-size: 12px;
}

.time_label_light {
  height: 50px;
  line-height: 50px; 
  text-align: right;
  padding-right: 15px;
  font-size: 12px;
  color: #666;
}

.time_slots {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

.time_slot {
  height: 50px;
}

.current-time-line {
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background-color: red;
  z-index: 0;
}

@keyframes slideInFromRight {
  from {
    transform: translateX(30px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-30px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-right-to-left {
  animation: slideInFromRight 0.2s ease-out;
}

.animate-left-to-right {
  animation: slideInFromLeft 0.2s ease-out;
}

/* SEMANA */

.week_header {
  display: grid;
  grid-template-columns: 65px repeat(7, 1fr);
  padding: 0.5rem 0;
}

.week_view_container {
  display: flex;
  width: 100%;
}

.week_grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  flex: 1;
}

.week_day_column {
  border-left: 1px solid #333;
  display: flex;
  flex-direction: column;
}


/* MES */

.month_navbar {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: .5rem;
}

.month_header {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  padding: 0.3rem 0;
}
.month_header_day {
  font-size: .8rem;
}

.month_grid {
  display: flex;
  flex-direction: column;
}

.month_row {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.month_row:last-child {
  border-bottom: none;
}

.month_cell {
  position: relative;
  height: 103px;
  padding: 0.4rem;
  cursor: pointer;
  text-align: center;
  font-size: .8rem;
}

.month_cell:last-child {
  border-right: none;
}

.other-month {
  opacity: 0.4;
}

.today {
  background-color: #2fa4e7;
  color: #000;
  border-radius: 100%;
  padding: 4px 5.5px;
  text-align: center;
  transition: all .3s ease;
}

.dia_del_mes {
  background-color: transparent;
  border-radius: 100%;
  padding: 4px 5.5px;
  text-align: center;
  transition: all .3s ease;
}

.dia_del_mes:hover {
  background-color: #247fb462;
  border-radius: 100%;
  padding: 4px 5.5px;
  text-align: center;
}

.current-month {
  opacity: 1;
}
