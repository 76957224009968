.chat-window {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #fff;
  }
  
  .messages-container {
    flex: 1;
    overflow-y: auto;
    border: 1px solid #ddd;
    padding: 10px;
    border-radius: 5px;
  }
  
  .message {
    margin: 10px 0;
    padding: 10px;
    border-radius: 5px;
    max-width: 80%;
  }
  
  .message.user {
    background-color: #d1e7dd;
    align-self: flex-end;
  }
  
  .message.ai {
    background-color: #f8d7da;
    align-self: flex-start;
  }
  
  .loading-indicator {
    text-align: center;
    color: #666;
    margin-top: 10px;
  }
  
  .input-container {
    display: flex;
    margin-top: 20px;
  }
  
  .input-container input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .input-container button {
    margin-left: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }