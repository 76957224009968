/* Estilos generales */
.availability-form-container {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
  padding: 25px;
}

.required-field:after {
  content: " *";
  color: #dc3545;
}

/* Pestañas */
.availability-tabs .nav-tabs {
  border-bottom: 2px solid #dee2e6;
}

.availability-tabs .nav-link {
  color: #495057;
  font-weight: 500;
  border: none;
  padding: 10px 20px;
  transition: all 0.3s ease;
}

.availability-tabs .nav-link:hover {
  color: #0d6efd;
  border: none;
}

.availability-tabs .nav-link.active {
  color: #0d6efd;
  border: none;
  border-bottom: 3px solid #0d6efd;
  background-color: transparent;
}

/* Selector de tiempo */
.time-selector-container {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.time-selector-row {
  align-items: center;
}

.add-time-btn {
  transition: all 0.2s ease;
  display: inline-flex;
  align-items: center;
}

.add-time-btn:hover {
  transform: translateY(-2px);
  text-decoration: none;
}

/* Tabla de horarios */
.availability-table {
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.availability-table thead {
  background-color: #f8f9fa;
  color: #495057;
}

.availability-table th {
  font-weight: 500;
  text-transform: uppercase;
  font-size: 0.8rem;
  letter-spacing: 0.5px;
}

.availability-table tbody tr {
  transition: background-color 0.2s ease;
}

.availability-table tbody tr:hover {
  background-color: #f8f9fa;
}

.delete-btn {
  transition: all 0.2s ease;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
}

.delete-btn:hover {
  transform: scale(1.2);
  color: #dc3545 !important;
  text-decoration: none;
}

/* Botones de acción */
.action-buttons {
  margin-top: 20px;
}

.action-buttons .btn {
  min-width: 100px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-submitting {
  position: relative;
  padding-right: 40px;
}

.btn-submitting:after {
  content: "";
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: white;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to { transform: translateY(-50%) rotate(360deg); }
}

/* Estilos para el calendario semanal */
.calendar-container {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.calendar-header {
  background-color: #f8f9fa;
  color: #495057;
  border-radius: 6px;
  padding: 8px 0;
  margin-bottom: 10px;
  border: 1px solid #dee2e6;
}

.calendar-day-header {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.calendar-day-header:hover {
  background-color: rgba(13, 110, 253, 0.1);
}

.calendar-day-header.selected {
  background-color: #0d6efd;
  color: white;
  border-radius: 4px;
}

.calendar-hours-container {
  border: 1px solid #dee2e6;
  border-radius: 6px;
  background-color: white;
}

.calendar-hour-row {
  border-bottom: 1px solid #dee2e6;
  transition: background-color 0.2s ease;
}

.calendar-hour-row:hover {
  background-color: #f8f9fa;
}

.calendar-hour-label {
  font-weight: 500;
  color: #495057;
}

.calendar-cell {
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease;
  border-radius: 3px;
  border: 1px solid #f1f1f1;
}

.calendar-cell:hover {
  background-color: #e9ecef;
}

.calendar-cell.selected {
  background-color: rgba(13, 110, 253, 0.1);
  box-shadow: inset 0 0 0 2px #0d6efd;
}

.calendar-cell.booked {
  background-color: rgba(25, 135, 84, 0.1);
  position: relative;
}

.calendar-event-indicator {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 20px;
  background-color: rgba(25, 135, 84, 0.3);
  border-radius: 3px;
}

/* Botones de navegación */
.calendar-nav-btn {
  min-width: 150px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

/* Modal del calendario */
.calendar-modal .modal-content {
  border-radius: 10px;
  border: none;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}

.calendar-modal .modal-header {
  background-color: #f8f9fa;
  color: #212529;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 1px solid #dee2e6;
}

.calendar-modal .modal-body {
  padding: 20px;
}

.calendar-modal .modal-footer {
  border-top: none;
  background-color: #f8f9fa;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* Estilos para switches */
.form-switch .form-check-input {
  width: 2.5em;
  height: 1.5em;
  cursor: pointer;
}

/* Mensaje cuando no hay horarios */
.text-muted i {
  opacity: 0.5;
}

/* Añade estos estilos al archivo existente */

/* Ancho aumentado para las horas */
.calendar-hour-label {
  width: 70px !important;
}

/* Vista de mes */
.month-view-container {
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.month-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.month-day {
  height: 60px;
  padding: 5px;
  border: 1px solid #eee;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.month-day:hover {
  background-color: #f8f9fa;
}

.month-day.other-month {
  opacity: 0.4;
  background-color: #f8f9fa;
}

.day-number {
  font-weight: bold;
  margin-bottom: 2px;
}

.has-exception {
  background-color: rgba(25, 135, 84, 0.1);
  border-color: rgba(25, 135, 84, 0.3);
}

.event-dot {
  width: 8px;
  height: 8px;
  background-color: #198754;
  border-radius: 50%;
  margin: 0 auto;
}

/* Modal de evento */
.event-modal .modal-content {
  border-radius: 10px;
}

.event-modal .modal-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
}

.event-modal .modal-body {
  padding: 20px;
}

/* Botones de vista */
.view-mode-buttons .btn {
  min-width: 80px;
}