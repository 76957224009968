/* Tipografía y fondo */
body {
  font-family: 'Nunito', sans-serif;
  background-color: #f9f9f9; /* Fondo blanco suave */
  color: #333; /* Texto gris oscuro */
  margin: 0;
  padding: 0;
}

/* Contenido de la página */
.page-content-task {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(90vh - 80px); /* Altura ajustada */
  margin-top: 0;
  overflow-x: auto; /* Habilita el scroll horizontal */
  overflow-y: auto; /* Desactiva el scroll vertical */
  padding: 20px;
  gap: 20px; /* Espaciado entre las columnas */
  box-sizing: border-box;
}

.page-content-task::-webkit-scrollbar {
  height: 6px; /* Estilo del scroll horizontal */
}

.page-content-task::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 8px;
}

.page-content-task::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}

/* Columnas de tareas */
.task-column {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin-right: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  min-width: 400px; /* Asegúrate de que el ancho mínimo sea adecuado */
  max-width: 400px; /* Puedes eliminar esto si no quieres limitar el ancho */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  min-height: 80%; /* Altura mínima de la columna */
  flex-grow: 1; /* Permite que las columnas crezcan si el contenido lo requiere */
  overflow-y: auto; /* Activar el scroll vertical cuando el contenido sea mayor que el alto mínimo */
  box-sizing: border-box;
}

.task-column::-webkit-scrollbar {
  width: 6px; /* Ancho del scroll (reduce el valor según lo desees) */
}

.task-column::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 8px;
}

.task-column::-webkit-scrollbar-track {
  background-color: #f0f0f0;
}
/* Botón "Agregar tarea" estilo plano */
.add-task-box {
  background-color: #f3f8fd; /* Fondo azul claro */
  color: #007bff; /* Texto azul */
  border: 1px dashed #007bff; /* Borde azul punteado */
  border-radius: 6px;
  margin-bottom: 10px;
  padding: 10px 15px;
  font-size: 14px;
  display: flex;
  align-items: center; /* Centrar el ícono con el texto */
  gap: 8px; /* Espaciado entre el ícono y el texto */
  cursor: pointer;
  width: 100%; /* Alinearlo con el ancho de la tarjeta */
  text-align: center;
  transition: background-color 0.3s ease, border 0.3s ease;
}

.add-task-box:hover {
  background-color: #d6ebff; /* Fondo ligeramente más oscuro */
  border-color: #0056b3; /* Borde más intenso */
  color: #0056b3; /* Texto más oscuro */
}

.add-task-box i {
  font-size: 16px; /* Tamaño del ícono */
  color: inherit; /* Hereda el color del texto */
}

.task-column .add-task-box p {
  margin: 0;
  font-size: 14px;
}


.task-column .delete-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 16px;
  color: #d32f2f;
  cursor: pointer;
}

.task-column .delete-btn:hover {
  color: #b71c1c;
}
/* Tarjeta de tarea */
.task-card {
  background-color: #fafafa; 
  border-radius: 6px;
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  flex-direction: column; /* Alineación vertical del contenido */
  gap: 10px;
  border: 1px solid #e0dfdf; /* Borde oscuro */
  box-shadow: 0 4px 8px rgba(231, 231, 231, 0.3); /* Sombra para darle profundidad */
  color: #858585; /* Texto blanco */
  font-size: 14px;
  position: relative;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
}

.task-card:hover {
  transform: scale(1.02);
}

.task-card.neutral .status-indicator {
  background-color: #ccc; /* Neutral */
}

.task-card.on-time .status-indicator {
  background-color: #28a745; /* Verde */
}

.task-card.warning .status-indicator {
  background-color: #ffc107; /* Amarillo */
}

.task-card.urgent .status-indicator,
.task-card.overdue .status-indicator {
  background-color: #dc3545; /* Rojo */
}

.status-indicator {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 6px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.task-card.overdue {
  border-color: #dc3545;
}

.task-card.urgent {
  border-color: #e54e5dda;
}

.task-card.warning {
  border-color: #ffda07cd;
}

.task-card.on-time {
  border-color: #28a761d0;
}

.task-title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.task-title input[type="checkbox"] {
  margin-right: 8px;
}

.task-title p {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}

.details {
  margin-bottom: 8px;
  font-size: 14px;
  color: #666;
}

.details span {
  margin-right: 16px;
}

.user-section {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.user-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #ccc;
  color: #fff;
  font-weight: bold;
  font-size: 12px;
  cursor: pointer;
  margin-right: 4px;
}

.add-user-btn {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-size: 16px;
}

.add-user-btn:hover {
  color: #0056b3;
}

.delete-btn {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 18px;
  color: #dc3545;
  cursor: pointer;
}

.delete-btn:hover {
  color: #a71d2a;
}

/* Título de la tarea */
.task-card p {
  margin: 0;
  font-weight: 600; /* Negrita para el título */
  font-size: 16px;
  color: #707070; 
}

/* Sección de detalles: fecha, etiquetas, etc. */
.task-card .details {
  display: flex;
  justify-content: space-between; /* Espaciado entre elementos */
  align-items: center;
  font-size: 13px;
  color: #3d3d3d;
}

.task-card .details span {
  display: flex;
  align-items: center;
  gap: 5px; /* Espaciado entre ícono y texto */
}

/* Íconos */
.task-card .details i {
  font-size: 14px;
  color: #464646;
}

/* Contenedor para usuarios y agregar botón */
.user-section {
  margin-top: 20px; /* Separación del contenido superior */
  padding-top: 10px; /* Espaciado interno para el borde */
  border-top: 1px solid #e0e0e0; /* Línea divisoria sutil */
  display: flex;
  align-items: center;
  justify-content: space-between; /* Íconos a la derecha */
  gap: 10px;
}

.user-section {
  display: flex;
  align-items: center;
  margin-top: 8px;
}



.add-user-btn {
  margin-left: auto;
  padding: 4px 8px;
  background: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.add-user-btn {
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  font-size: 16px;
}

.add-user-btn:hover {
  color: #333;
}

/* Estilo del marcador "+X" */
.extra-users {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #e0e0e0; /* Fondo gris claro */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #555;
  font-weight: bold;
  border: 1px solid #ddd;
  cursor: default;
}
/* Botón para agregar usuarios */
.add-user-btn {
  background-color: transparent;
  color: #8c00ff; /* Azul para ícono */
  border: none;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  transition: color 0.3s ease, transform 0.3s ease;
}

.add-user-btn:hover {
  color: #0056b3; /* Azul más oscuro al pasar el cursor */
  transform: scale(1.2); /* Efecto de agrandamiento */
}

/* Botón "Agregar flujo nuevo" */
.add-column-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 15px 20px;
  font-size: 14px;
  cursor: pointer;
  align-self: flex-start;
  margin: 10px;
  transition: background-color 0.3s ease;
}

.add-column-btn:hover {
  background-color: #0056b3;
}

/* Formulario de tareas */
.task-form {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.task-form input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.task-form button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.task-form button:hover {
  background-color: #0056b3;
}

/* Columna para agregar un nuevo flujo con header */
.new-flow-column {
  background-color: #f0f4f7; /* Fondo más claro */
  border: 1px dashed #b0b0b0; /* Borde punteado */
  border-radius: 8px;
  padding: 20px;
  min-width: 300px;
  max-width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.new-flow-column:hover {
  background-color: #e4ebf1; /* Fondo más oscuro al pasar */
}

.new-flow-column h5 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  color: #007bff;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color 0.3s ease;
}

.new-flow-column h5 i {
  margin-right: 8px; /* Espaciado entre ícono y texto */
  font-size: 20px;
}

.new-flow-column h5:hover {
  color: #0056b3;
}


/* TaskFlow.css */

.custom-modal-step {
  max-width: 800px;
  background-color: #f4f4f4;
  border-radius: 8px;
  padding: 20px;
}

.task-info {
  border-bottom: 1px solid #ddd;
  margin-bottom: 15px;
  padding-bottom: 10px;
}

.task-title {
  display: flex;
  align-items: center;
  gap: 8px; /* Espacio entre el radio button y el título */
}

.task-title input[type="radio"] {
  margin: 0;
  cursor: pointer;
}

.task-meta {
  font-size: 0.9rem;
  color: #6c757d;
}

.form-section {
  display: flex;
  gap: 15px;
}

.date-section {
  display: flex;
  gap: 15px;
}

.tags-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.tag-item {
  background-color: #e0f7fa;
  color: #00796b;
  padding: 5px 10px;
  border-radius: 12px;
  font-size: 0.85rem;
}

.assigned-list {
  margin-top: 10px;
}

.icon-button {
  margin-left: 10px;
}

.completed-step {
  text-decoration: line-through;
  color: #6c757d;
  opacity: 0.6;
}

.list-group-item {
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.list-group-item:hover {
  background-color: #f8f9fa;
}
/* Estilos para las tarjetas de los steps */
.step-card {
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.step-card .card-body {
  padding: 1rem;
}

.step-card .step-title {
  font-size: 1.1rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.step-card .step-due-date {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 1rem;
}

/* Estilos para el área de comentarios */
.comments-section {
  margin-top: 1rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.comments-section h6 {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.comments-list {
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 1rem;
}

.comment-item {
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  border-bottom: 1px solid #eee;
}

.comment-item strong {
  font-size: 0.9rem;
  color: #333;
}

.comment-item p {
  font-size: 0.9rem;
  color: #555;
  margin-bottom: 0;
}

.comment-input {
  width: 100%;
  margin-bottom: 0.5rem;
}

.comment-button {
  width: 100%;
}
/* En TaskFlow.css */


.icon-button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.icon-button i {
  font-size: 16px;
}
/* En tu archivo de estilos (por ejemplo, Contacts.module.scss) */
.table-container-task {
  display: block !important; /* Anula el grid */
  width: 100% !important; /* Asegura que ocupe todo el ancho */
}

.table-custom-task {
  width: 100%;
  border-collapse: collapse;
  margin: 1rem 0;

  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f8f9fa;
    font-weight: 600;
  }

  tr:hover {
    background-color: #f1f1f1;
  }
}
.task-list-container {
  padding: 20px;
}

.task-list-item {
  background: white;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  cursor: pointer;
  transition: transform 0.2s;
}

.task-list-item:hover {
  transform: translateY(-3px);
}

.task-list-details {
  margin: 10px 0;
  color: #666;
  font-size: 0.9em;
}

.task-list-assignees {
  display: flex;
  gap: 5px;
  margin-top: 10px;
}

/* Agregar estilo para el botón activo */
.active-view {
  border-bottom: 2px solid #007bff;
  color: #007bff !important;
}




/*TaskList*/

/* Contenedor principal */
.task-list-container {
  padding: 2rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Header con pestañas */
.task-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

/* Pestañas personalizadas */
.task-list-tabs {
  border-bottom: none;
}

.task-list-tabs .nav-link {
  border: none;
  color: #6c757d;
  font-weight: 500;
  padding: 0.5rem 1.5rem;
  margin-right: 0.5rem;
  border-radius: 0.5rem 0.5rem 0 0;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

.task-list-tabs .nav-link.active {
  color: #fff;
  background-color: #3b7ddd;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.task-list-tabs .dark-tab {
  color: #adb5bd;
}

.task-list-tabs .dark-tab.active {
  background-color: #495057;
}

/* Badge de conteo */
.task-count-badge {
  background-color: #e9ecef;
  color: #495057;
  border-radius: 10px;
  padding: 0.15rem 0.5rem;
  font-size: 0.75rem;
  margin-left: 0.5rem;
}

.task-list-tabs .nav-link.active .task-count-badge {
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
}

/* Botón de agregar */
.task-list-add-btn {
  font-weight: 500;
  padding: 0.5rem 1.25rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Tabla de tareas */
.task-list-table {
  border-radius: 0.5rem;
  overflow: hidden;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.task-list-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  padding: 1rem;
}

.task-list-table td {
  padding: 0.75rem 1rem;
  vertical-align: middle;
}

.task-list-table tr {
  transition: background-color 0.2s;
  cursor: pointer;
}

.task-list-table tr:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

/* Badge de estado */
.task-status-badge {
  padding: 0.25rem 0.75rem;
  border-radius: 50px;
  font-size: 0.75rem;
  font-weight: 600;
}

.task-status-badge.pending {
  background-color: #fff3cd;
  color: #856404;
}

/* Avatar de usuario */
.task-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #3b7ddd;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 0.75rem;
}

/* Botones de acción */
.task-action-btn {
  width: 32px;
  height: 32px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

/* Modal personalizado */
.task-list-modal .modal-header {
  background-color: #f8f9fa;
  border-bottom: none;
  padding: 1.25rem 1.5rem;
}

.task-list-modal .modal-body {
  padding: 1.5rem;
}

.task-list-modal-footer {
  border-top: none;
  padding: 1rem 1.5rem;
}

.task-list-input {
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid #ced4da;
}

.task-list-date-preview {
  margin-top: 0.5rem;
  font-size: 0.85rem;
  color: #6c757d;
  font-style: italic;
}

.task-list-modal-btn {
  padding: 0.5rem 1.25rem;
  border-radius: 0.5rem;
  font-weight: 500;
}

/* Estilos para tema oscuro */
body.dark .task-list-table th {
  background-color: #343a40;
  color: #f8f9fa;
}

body.dark .task-list-table tr {
  background-color: #495057;
  color: #f8f9fa;
}

body.dark .task-list-table tr:hover {
  background-color: #3d434a;
}

body.dark .task-list-modal .modal-header {
  background-color: #343a40;
  color: white;
}

body.dark .task-list-modal .modal-content {
  background-color: #495057;
  color: white;
}

body.dark .task-list-input {
  background-color: #343a40;
  border-color: #6c757d;
  color: white;
}

body.dark .task-list-date-preview {
  color: #adb5bd;
}